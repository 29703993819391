<template>
  <div>
    <!-- <div class="split overridesplitleft left">
      <img
        src="@/assets/images/logo/sc-logo.png"
        alt="asd"
        style="width:400px;height:250px;"
      >
    </div> -->

    <div class="d-flex flex-column justify-content-center split overridesplitright right">
      <div
        class="screensize"
        style="margin:auto;"
      >
        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-2">
          <span style="font-size: clamp(28px,5vw,36px); color:black;"><strong>{{ $t ("Update your password") }}</strong></span>
        </div>

        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-1">
          <span style="font-size: clamp(16px,5vw,24px); "><strong>{{ $t ("Enter code that we sent to the email ending") }}</strong></span>
        </div>
        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-1">
          <span style="font-size: clamp(16px,5vw,24px); "><strong>{{ obsecure }}</strong></span>
        </div>

        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-2 " />
        <!-- EMAIL:<span>{{ getEmail }}</span><br> -->
        <!-- <span>{{ allPin }}</span> -->
        <div class="form-inline mb-3 justify-content-center test">
          <input
            id="satu"
            v-model="pin1"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="satu"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="dua"
            v-model="pin2"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="dua"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="tiga"
            v-model="pin3"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="tiga"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="empat"
            v-model="pin4"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="empat"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="lima"
            v-model="pin5"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="lima"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="enam"
            v-model="pin6"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="enam"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
        </div>

        <div
          class="d-flex justify-content-between"
          style="font-size: clamp(14px,5vw,18px);"
        >
          <router-link
            :to="{name:'forgot-password'}"
            class=""
            style="color:#000;"
          ><i
            class="fas fa-long-arrow-alt-left"
            style="color:#000;"
          />&nbsp;&nbsp;{{ $t ("Back") }}</router-link>
          <a
            style="color:#2F80ED; text-align: right;"
            @click="apiSendEmail()"
          >{{ $t ("Resend Code") }}</a>
        </div>
      </div>

    </div>

  </div>

</template>
<style scoped>
    .split {
      height: 100%;
      width:50%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }

    .left {
      background: url('~@/assets/images/background/login.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      /* background-image: url('~@/assets/images/background/login.png');
      height: 100%; */
      background-position: center;
    }
    .right {
      right: 0;
      background-color: white;
    }

    .centered {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 100px;
      text-align: center;
    }

    .centered img {
      width: 150px;
      border-radius: 50%;
    }

    .code{
      -webkit-appearance: none!important;
      -moz-appearance: textfield!important;
      border-radius: 6px 6px;
      border-width: 2px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font-size: 34px;
      font-weight: lighter;
      height: 80px;
      margin-left: 4%;
      min-width: 20px;
      max-width: 52px;
      text-align: center;
      -o-text-overflow: clip;
      text-overflow: clip;
    }

    .screensize{
      width: 48%;
    }

    /* Split screen */
    @media screen and (max-width: 1100px) {
        .overridesplitright{
          width: 100% !important;
        }

        .overridesplitleft{
          display: none !important;
        }
    }
    /* End Split screen */
    @media screen and (max-width: 2000px) {
        .screensize{
          width: 48%;
        }

        .code{
          font-size:18px;
          height: 72px;
          margin-left: 3%;
          width: 10px;
        }
    }
    @media screen and (max-width: 1700px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1400px) {
      .screensize{
        width: 66%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1200px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1100px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 800px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 500px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 400px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 300px) {
      .screensize{
        width: 86%;
      }

      .code{
        font-size:10px;
        height: 52px;
        margin-left: 2%;
        width: 10px;
      }
    }
</style>

<script>
import SAJToast from "@/component/saj-toastification.vue"

export default {
  components: { },
  data() {
    return {
      // email: "anisreenaadira@gmail.com",
      email: '',
      obsecure: "",
      allPin: null,
      pin1: null,
      pin2: null,
      pin3: null,
      pin4: null,
      pin5: null,
      pin6: null,

    }
  },

  beforeMount() {
    this.getEmail = this.$route.params.getEmail
  },
  mounted(){
    this.obsecureEmail()
  },
  methods: {

    // securityCode() {
    //   if (document.getElementById('satu').value === 1) {
    //     if (document.getElementById('dua').value === 2) {
    //       if (document.getElementById('tiga').value === 3) {
    //         if (document.getElementById('empat').value === 4) {
    //           if (document.getElementById('lima').value === 5) {
    //             if (document.getElementById('enam').value === 6) {
    //               this.$router.push('reset-password')
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },

    // autoline(){
    //   const container = document.getElementsByClassName("test")[0]
    //   container.onkeyup = function bar(e) {
    //     const target = e.srcElement
    //     const maxLength = parseInt(target.attributes.maxlength.value, 10)
    //     const myLength = target.value.length
    //     if (myLength >= maxLength) {
    //       const next = target
    //       while (next === next.nextElementSibling) {
    //         if (next === null) break
    //         if (next.tagName.toLowerCase() === "input") {
    //           next.focus()
    //           break
    //         }
    //       }
    //     }
    //   }
    // },

    autoline(e){
      // const container = document.getElementsByClassName("screensize")[0]
      // eslint-disable-next-line func-names
      // container.onkeyup = function (e) {
      const target = e.srcElement
      const maxLength = parseInt(target.attributes.maxlength.value, 10)
      const myLength = target.value.length
      if (myLength >= maxLength) {
        const next = target
        // while (next !== next.nextElementSibling) {
        //   // if (next == null) break
        //   // if (next.tagName.toLowerCase() === "input") {
        //   //   next.focus()
        //   //   break
        //   // }
        //   next.nextElementSibling.focus()
        //   break
        // }
        if (next.nextElementSibling !== null){
          next.nextElementSibling.focus()
        } else {
          // this.$router.push({ name: 'reset-password' })
          this.sendPin()
        }
      }
      // }
    },

    sendPin(){
      this.allPin = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6

      const data = new FormData()
      data.append('email', this.getEmail)
      data.append('pinnumber', this.allPin.toString())
      // data.forEach((value, key) => {
      //   console.log(`${key} ${value}`)
      // })
      this.$axios.post(`${this.$baseUrl}/password/pinnumber`, data).then(response => {
        // console.log(response)
        // console.log(response.data.success)
        if (response.data.success === true){
          this.$router.push({ name: 'reset-password', params: { getEmail: this.getEmail } })
        }
        // else {
        //   this.$errorConfirmation()
        // }
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Wrong pin number for email')}.`,
              icon: "XIcon",
              iconColor: 'white',
              iconBg: '#e80202',
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },
    apiSendEmail() {
      const data = new FormData()
      data.append('email', this.getEmail)

      this.$axios.post(`${this.$baseUrl}/password/forgot`, data).then(response => {
        // console.log('append email', response)
        if (response !== null){
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('A code has been sent to your email')}`,
                  icon: 'AlertCircleIcon',
                  variant: 'success',
                  iconBg: "#53b7db",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          }, 1000)
        }
      })
    },

    obsecureEmail(){
      const [name, domain] = this.getEmail.split('@')
      this.obsecure = `${name[0]}${new Array(name.length - 1).join('*')}${name[name.length - 1]}@${domain}`
      return this.obsecure
    },

  },

}

</script>
